import React from 'react'
import { Container, Row } from 'styled-bootstrap-grid2'
import Container3Col from '../../../components/container_3col'
import ProductListFilterTag from '../../../components/product_list_filter_tag'
import SEO from '../../../components/seo'

const Shop = () => (
  <div>
  <SEO title="Shop - Labradorite" keywords={[`Shop`, `Jewellery`,`Labradorite`, `Theodora Warre`]} />
    <Container3Col longpage top>
      <Container>
        <Row>
          <ProductListFilterTag tag="Labradorite" />
        </Row>
      </Container>
    </Container3Col>
  </div>
)

export default Shop
